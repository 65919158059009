import React from 'react';

const Home = () => {
	return (
		<div className="hero-background" id="Home">
			<div className="hero-container">
				<div className="hero-text">
					<h1>
						Front-End React <br /> Developer
					</h1>
					<p>Hello, I am Sean Joseph. A Front-end React Developer based out of Philadelphia.</p>
					<a
						href="https://www.linkedin.com/in/sean-joseph-99969186/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<span className="social-icons">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="tabler-icon tabler-icon-brand-linkedin"
							>
								<path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
								<path d="M8 11l0 5" />
								<path d="M8 8l0 .01" />
								<path d="M12 16l0 -5" />
								<path d="M16 16v-3a2 2 0 0 0 -4 0" />
							</svg>
						</span>
					</a>
					<a href="https://github.com/Sean-Joseph" target="_blank" rel="noopener noreferrer">
						<span className="social-icons">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="tabler-icon tabler-icon-brand-github"
							>
								<path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
							</svg>
						</span>
					</a>
				</div>
				<div className="hero-img" />
			</div>
			<div className="tech-stack">
				<p>Tech Stack </p>
				<ul>
					<li>
						<img src="https://skillicons.dev/icons?i=html,css,js,react,nextjs" alt="" />
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Home;
