import React from 'react';

const Navbar = () => {
	return (
		<nav>
			<div class="navbar">
				<div class="logo">
					<a href="#">Sean Joseph</a>
				</div>
				<ul class="menu">
					<li>
						<a href="#Home">Home</a>
					</li>
					<li>
						<a href="#About">About</a>
					</li>
					<li>
						<a href="#Projects">Portfolio</a>
					</li>
					<li>
						<a href="#Contact">Contact</a>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
