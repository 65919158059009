import React from 'react';
import aboutImage from '../assets/images/about-me.jpg';

const About = () => {
	return (
		<div className="about-container" id="About">
			<img className="about-img" src={aboutImage} />
			<div className="about-content">
				<h2>ABOUT ME</h2>
				<h3>A dedicated Front-end Developer based in Philadelphia, US</h3>
				<p>
					As a Junior Front-End Developer, I possess an impressive arsenal of skills in HTML, CSS, JavaScript,
					React, Tailwind, and SCSS. I excel in designing and maintaining responsive websites that offer a
					smooth user experience. My expertise lies in crafting dynamic, engaging interfaces through writing
					clean and optimized code and utilizing cutting-edge development tools and techniques.
				</p>
			</div>
		</div>
	);
};

export default About;
