import React from 'react';
import { useState } from 'react';
import maisNoir from '../assets/images/mais-noir.png';
import djCousinMike from '../assets/images/djcousinmike.png';
import ja from '../assets/images/ja.png';

const Projects = () => {
	const [ projects, setProject ] = useState([
		{
			title: 'DJ Cousin Mike (2022)',
			src: `${djCousinMike}`,
			blurb:
				'This website was created to allow a DJ hailing from Philadelphia an opportunity to have a space to share his craft with the digital world.',
			type: 'right',
			techUsed: [ 'HTML', 'CSS', 'JavaScript' ],
			link: 'https://djcousinmike.com/',
			id: 1
		},
		{
			title: 'Mais Noir (2024)',
			src: `${maisNoir}`,
			blurb:
				'Mais noir is a filter that takes the ostensibly objective SERP (search engine results pages) we, digital citizens, are all too familiar with and reconciles it with an interpretation of anti-blackness that is real, not theoretical.',
			type: 'left',
			techUsed: [ 'React', 'APIs', 'Next.js' ],
			link: 'https://extraordinary-lokum-ab20a1.netlify.app',
			id: 2
		},
		{
			title: 'Arnold Joseph (Coming Soon)',
			src: `${ja}`,
			blurb:
				'This website was created to provide an online presence for this litigator in the Philadelphia and surrounding area. He came to me with nothing but a logo design, and I took that design and transformed it into a fully fleshed out website.',
			type: 'right',
			techUsed: [ 'Bootstrap', 'GSAP', 'JS' ],
			link: 'http://xpi.e05.mytemp.website/',
			id: 3
		}
	]);

	return (
		<div className="portfolio-container" id="Projects">
			<div className="portfolio-header">
				<h2>Portfolio</h2>
				<h3>Examples of projects I have completed can be found below</h3>
			</div>
			<div className="card-details">
				{projects.map((project) => (
					<div className={project.type} key={project.id}>
						<div className="project-links">
							<h2>{project.title}</h2>
							<p>{project.blurb}</p>
							<span className="techUsed">{project.techUsed[0]}</span>
							<span className="techUsed">{project.techUsed[1]}</span>
							<span className="techUsed">{project.techUsed[2]}</span>
							<a href={project.link} target="_blank" rel="noopener noreferrer">
								Live Demo
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="tabler-icon tabler-icon-external-link"
								>
									<path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
									<path d="M11 13l9 -9" />
									<path d="M15 4h5v5" />
								</svg>
							</a>
						</div>
						<div className="image-wrap">
							<a href={project.link} target="_blank" rel="noopener noreferrer">
								<img src={project.src} />
							</a>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Projects;
